import {useSelector} from "react-redux";
import {selectJsonRequest} from "../state/global";
import {useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Divider,
    Form,
    message,
    Modal,
    Popover,
    Progress, Select,
    Table,
    Tag,
} from "antd";
import dayjs from "dayjs";
import GlobalWrap from "../state/globalWrap";
import Detail from "./components/Detail";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import zh from "javascript-time-ago/locale/zh-Hans-MO.json";
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";
import css from "./home.module.css";
import {useLocalStorageState} from "ahooks";
import {useNavigate} from "react-router-dom";
import {ChangePassword} from "./components/ChangePassword";

TimeAgo.addDefaultLocale(zh);

let _page = 1;
let _pageSize = 15;

export default function Home() {
    const jsonClient = useSelector(selectJsonRequest);
    const [data, setData]: any = useState();
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [userStore, setUserStore] = useLocalStorageState<{
        id: string;
        name: string;
        telephone?: string;
        queryAuthority?:string
    }>("user");
    useEffect(() => {
        // 获取 User-Agent 字符串
        const userAgent = window.navigator.userAgent;
        // 判断是否是手机
        const isMobile = /Mobile/i.test(userAgent);

        if (isMobile) {
            navigate("/List?key=1");
            return;
        }
        // console.log(isMobile)
        handlePageTo(1);

        let id = setInterval(() => handlePageTo(_page, _pageSize), 2000);

        return () => {
            clearInterval(id);
        };
    }, []);

    // 点击打开详情
    const handlePageTo = async (page: number, size: number = _pageSize) => {
        _page = page;
        _pageSize = size;
        let data = await jsonClient.get("/v1/inquiry/record", {
            pageNumber: page,
            pageSize: size,
            type:userStore?.queryAuthority
        });
        setLoading(false);
        setData(data.data);
    };

    // 点击打开详情
    const handleClickDetail = async (id: any) => {
        let data = await jsonClient.get(`/v1/inquiry/record/detail/${id}`, {});
        Modal.confirm({
            width: 1600,
            closable: true,
            maskClosable: true,
            title: "问诊单",
            content: (
                <GlobalWrap>
                    <Detail form={form} item={data.data || {}}/>
                </GlobalWrap>
            ),
            onOk: (close) => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        console.log(values);
                        jsonClient
                            .post(`/v1/inquiry/record/result`, {
                                ...values,
                                doctorName: userStore?.name,
                                doctorId: userStore?.id,
                                inquiryRecordId: id,
                                prescriptionPictures: values.prescriptionPictures
                                    ?.map((item: any) => {
                                        if (item.url?.includes("https://")) {
                                            return item.url;
                                        }
                                        return (
                                            "https://tcm-applet.zhi-yuan.net/v1/aliyun/authorization?objectKey=" +
                                            item.url
                                        );
                                    })
                                    .join(","),
                            })
                            .then(() => {
                                message.success("保存成功");
                                close();
                            });
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
                return false;
            },
        });
    };

    const handleDelete = async (inquiryRecordId: number) => {
        let data = await jsonClient.delete(
            `/v1/inquiry/record/${inquiryRecordId}`,
            {},
        );
        if (data.data === true) message.success("删除成功");

        handlePageTo(_page, _pageSize);
    };

    function loginOut() {
        setUserStore(undefined);
        navigate("/login", {replace: true});
    }

    const [modal, contextHolder] = Modal.useModal();

    function changePassword() {
        modal.confirm({
            width: 400,
            closable: true,
            maskClosable: true,
            title: "修改密码",
            content: <ChangePassword/>,
            footer: null,
        });
    }



    return (
        <div style={{padding: 20}}>
            <div className={css.header}>
                <h1>{(userStore?.queryAuthority==""||userStore?.queryAuthority==null) ? "问诊单":userStore?.queryAuthority=="six_meridians"? "六经辨证问诊单" :"通用问诊单"}列表</h1>
                <div>
                    <Button type="text">{userStore?.name}</Button>
                    <Button type="link" onClick={changePassword}>
                        修改密码
                    </Button>
                    <Button type="link" onClick={loginOut}>
                        退出
                    </Button>
                </div>
            </div>
            <Table
                dataSource={data?.list}
                size="small"
                loading={loading}
                rowKey={(item) => item.id}
                pagination={{
                    pageSizeOptions: [10, 20, 50, 100],
                    onChange: (page, size) => {
                        handlePageTo(page, size);
                    },
                    total: data?.total,
                    current: data?.pageNumber,
                    pageSize: data?.pageSize || 1,
                }}
            >
                {/* <Table.Column title="" width={180} dataIndex="inquiryRecordId" key="inquiryRecordId"></Table.Column> */}
                <Table.Column
                    title="头像"
                    align="center"
                    width={80}
                    dataIndex="headUrl"
                    key="headUrl"
                    render={(text, item) => (
                        <Avatar
                            style={{cursor: "fingger"}}
                            onClick={() => handleClickDetail(item.inquiryRecordId)}
                            src={text}
                        />
                    )}
                ></Table.Column>
                <Table.Column
                    title="微信昵称"
                    dataIndex="nickName"
                    key="nickName"
                    render={(text, item) => (
                        <a onClick={() => handleClickDetail(item.inquiryRecordId)}>
                            {" "}
                            {text}
                        </a>
                    )}
                ></Table.Column>
                <Table.Column title="姓名" dataIndex="name" key="name"></Table.Column>
                <Table.Column
                    title="性别"
                    align="center"
                    dataIndex="sex"
                    key="sex"
                    render={(text) => (text === "man" ? "男" : "女")}
                ></Table.Column>
                <Table.Column
                    title="年龄"
                    align="center"
                    dataIndex="age"
                    key="age"
                ></Table.Column>
                <Table.Column
                    title="联系方式"
                    align="center"
                    dataIndex="contactWay"
                    key="contactWay"
                ></Table.Column>
                <Table.Column
                    title="问诊进度"
                    dataIndex="rate"
                    key="rate"
                    render={(i) => (
                        <Progress percent={Math.trunc(i * 100)} size="small"/>
                    )}
                ></Table.Column>
                <Table.Column
                    title="诊断状态"
                    dataIndex="diagnoseResult"
                    key="diagnoseResult"
                    render={(val, i) => {
                        const isTrue = [
                            i.illnessName,
                            i.diagnoseResult,
                            i.resolutionDisease,
                            i.fourDiagnosis,
                            i.otherQuestion,
                            i.prescriptionPictures,
                        ].some(Boolean);
                        return (
                            <Tag bordered={false} color={isTrue ? "success" : "default"}>
                                {isTrue ? "已诊断" : "未诊断"}
                            </Tag>
                        );
                    }}
                ></Table.Column>
                <Table.Column
                    title="诊断医生"
                    dataIndex="doctorName"
                    key="doctorName"
                ></Table.Column>
                <Table.Column
                    title="多久前"
                    align="center"
                    width={150}
                    dataIndex="createTime"
                    key="createTime"
                    render={(text) => <ReactTimeAgo date={text}/>}
                ></Table.Column>
                <Table.Column
                    title="问诊时间"
                    align="center"
                    width={150}
                    dataIndex="createTime"
                    key="createTime"
                    render={(text) => dayjs(text).format("YYYY-MM-DD HH:mm")}
                ></Table.Column>
                <Table.Column
                    title="操作"
                    width={200}
                    dataIndex="inquiryRecordId"
                    render={(inquiryRecordId, item: any) => {
                        return (
                            <div>
                                <Button
                                    type="link"
                                    onClick={() => handleClickDetail(inquiryRecordId)}
                                    icon={<SearchOutlined/>}
                                >
                                    查看
                                </Button>
                                <Divider type="vertical"/>
                                <Popover
                                    trigger="hover"
                                    content={
                                        <Button
                                            danger
                                            size="small"
                                            type="primary"
                                            onClick={() => handleDelete(inquiryRecordId)}
                                            icon={<DeleteOutlined/>}
                                        >
                                            确认
                                        </Button>
                                    }
                                >
                                    <Button type="link" danger icon={<DeleteOutlined/>}>
                                        删除
                                    </Button>
                                </Popover>
                            </div>
                        );
                    }}
                ></Table.Column>
            </Table>
            {contextHolder}
        </div>
    );
}
