import { useSelector } from "react-redux";
import { selectJsonRequest } from "../state/global";
import { useEffect, useState } from "react";
import {
    Form,
} from "antd";

import TimeAgo from "javascript-time-ago";
import zh from "javascript-time-ago/locale/zh-Hans-MO.json";

import "./mobile/list.css";

import {useNavigate, useSearchParams} from "react-router-dom";


import Test from "./components/test";

import Mine from "./components/Mine";
TimeAgo.addDefaultLocale(zh);


export default function List() {
    const [searchParams] = useSearchParams();
    console.log(searchParams.get("key"))
    const key:string|null = searchParams.get("key");
    const jsonClient = useSelector(selectJsonRequest);
    const [data, setData]: any = useState();
    const [loading, setLoading] = useState(true);
    const [selectActive, setSelectActive] = useState(1);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        // 判断是否是手机
        const isMobile = /Mobile/i.test(userAgent);
        if(!isMobile){
            navigate("/");
            return ;
        }
        if (!localStorage.getItem("user")) {
            navigate("/login");
        }
    }, []);

    return (
        <div className='box'>
            {/*{( selectActive == 0) && (<Diagnosis selectActive={selectActive} ></Diagnosis>)}*/}
            {(selectActive == 0) && <Test selectActive={selectActive} ></Test>}
            {(selectActive == 1) && <Test selectActive={selectActive} ></Test>}
            {/*{( selectActive == 1) && (<Diagnosis1 selectActive={selectActive} ></Diagnosis1>)}*/}
            {/*<div className={selectActive == 1 ||selectActive == 0? 'show':'notShow'}>*/}
            {/*    */}
            {/*</div>*/}
            <div className={selectActive == 2 ? 'show':'notShow'}>
                <Mine></Mine>
            </div>
            <div className="bottomBox">
                <div  className={key == "0" ? 'active':''}  onClick={()=>{
                    setSelectActive(0);
                    navigate("/List?key=0")
                }}>
                    已诊断
                </div>
                <div  className={key == "1" ? 'active':''}  onClick={()=>{
                    setSelectActive( 1);
                    navigate("/List?key=1")
                }}>
                    未诊断
                </div>
                <div className={key == "2"? 'active':''} onClick={()=>{
                    setSelectActive( 2)
                    navigate("/List?key=2")

                }}>
                    我的
                </div>
            </div>
        </div>
    );
}
