import { Divider, Form, Image, Input } from "antd";
import { useEffect } from "react";
import { AliyunOSSUpload } from "../../components/AliyunOSSUpload";

export default function Detail({ form, item }: any) {
  useEffect(() => {
    form.setFieldsValue({
      fourDiagnosis: item.fourDiagnosis,
      illnessName: item.illnessName,
      diagnoseResult: item.diagnoseResult,
      resolutionDisease: item.resolutionDisease,
      otherQuestion: item.otherQuestion,
      prescriptionPictures: item.prescriptionPictures
        ?.split(",")
        .map((url: string) => {
          return {
            uid: url,
            url: url,
            name: url,
            status: "done",
          };
        }),
    });

    return () => {};
  }, []);

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        size="small"
        wrapperCol={{ span: 14 }}
        form={form}
        labelWrap
      >
        <div style={{display: "flex"}}>
          <div style={{flex: 2}}>
            <Divider>基本信息</Divider>
            <Form.Item label="姓名">
              <b>{item.name}</b>
            </Form.Item>
            <Form.Item label="性别">
              <b>{item.sex == "man" ? "男" : "女"}</b>
            </Form.Item>
            <Form.Item label="年龄">
              <b>{item.age}</b>
            </Form.Item>
            <Form.Item label="联系方式">
              <b>{item.contactWay || "无"}</b>
            </Form.Item>
            <Form.Item label="既往病史">
              <b>{item.medicalHistory || "无"}</b>
            </Form.Item>
            <Form.Item
                label={
                  <span>
                  过敏史
                  <br/>
                  遗传史
                  <br/>
                  手术史
                </span>
                }
            >
              <b> {item.irritabilityHistory || "无"}</b>
            </Form.Item>
            {item?.illnessDetail?.templateName?.indexOf("女") > -1 && (
                <Form.Item label="是否妊娠哺乳期">
                  <b>{item.gestation ? "是" : "否"}</b>
                </Form.Item>
            )}
          </div>
          {(item.height||item.weight || item.highPressure || item.lowPressure || item.pulseRate || item.bloodGlucose)&&
            <div style={{flex: 2}}>
              <Divider>补充信息</Divider>

              <Form.Item label="身高">
                <b>{item.height ? item.height + '厘米' : "无"}</b>
              </Form.Item>
              <Form.Item label="体重">
                <b>{item.weight ? item.weight + "公斤" : "无"}</b>
              </Form.Item>
              <Form.Item label="高压">
                <b>{item.highPressure ? item.highPressure + "mmHg" : "无"}</b>
              </Form.Item>
              <Form.Item label="低压">
                <b>{item.lowPressure ? item.lowPressure + "mmHg" : "无"}</b>
              </Form.Item>
              <Form.Item label="脉率">
                <b>{item.pulseRate ? item.pulseRate + "(次/分)" : "无" }</b>
              </Form.Item>
              <Form.Item label="血糖">
                <b>{item.bloodGlucose ? item.bloodGlucose +"(mmol/L)": "无"}</b>
              </Form.Item>
            </div>

          }

          <div style={{flex: 3}}>
            <Divider>病历描述</Divider>
            <Form.Item label="描述">
              <b>{item.symptomDescription}</b>
            </Form.Item>
            <div>
              <Form.Item label="舌诊照片">
                {item?.aboveTonguePicture && (
                    <Image
                        style={{border: "5px solid #fff", borderRadius: 10}}
                        width={80}
                        height={80}
                        src={item.aboveTonguePicture}
                    ></Image>
                )}
                {item?.belowTonguePicture && (
                    <Image
                        style={{border: "5px solid #fff", borderRadius: 10}}
                        width={80}
                        height={80}
                        src={item.belowTonguePicture}
                    ></Image>
                )}
              </Form.Item>
              <Form.Item label="面部病患处照片">
                {item?.illnessPicture
                    ?.split(",")
                    .map(
                        (i: any) =>
                            i && (
                                <Image
                                    style={{border: "5px solid #fff", borderRadius: 10}}
                                    key={i}
                                    width={80}
                                    height={80}
                                    src={i}
                                ></Image>
                            ),
                    )}
              </Form.Item>
              <Form.Item label="就诊病例用药情况">
                {item?.medicalHistoryUrl
                    ?.split(",")
                    .map(
                        (i: any) =>
                            i && (
                                <Image
                                    style={{border: "5px solid #fff", borderRadius: 10}}
                                    key={i}
                                    width={80}
                                    height={80}
                                    src={i}
                                ></Image>
                            ),
                    )}
              </Form.Item>
            </div>
          </div>
          {!!item?.typeAnswers?.length && (
              <div style={{flex: 6}}>
                <Divider>病症详情</Divider>
                {item?.typeAnswers?.map((item: any) => (
                    <div
                        style={{
                          display: "flex",
                          flexFlow: "row wrap",
                          marginBottom: "15px",
                        }}
                        key={item.typeId}
                    >
                  <span
                      style={{
                        whiteSpace: "nowrap",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                  >
                    {item.typeName}：
                  </span>
                      {item.answers.map((a: any) => {
                        return (
                            <>
                        <span
                            style={{
                              wordWrap: "break-word",
                              fontSize: "15px",
                              marginRight: "15px",
                            }}
                        >
                          {!a.hideTitle && <span>{a.question}：</span>}
                          <b>{a.answer}</b>
                        </span>
                            </>
                        );
                      })}
                    </div>
                ))}
              </div>
          )}
        </div>
        <Divider>辩证结果</Divider>
        <div style={{display: "flex"}}>
          <div style={{flex: 1}}>
            <Form.Item label="四诊" name="fourDiagnosis">
              <Input.TextArea autoSize style={{minHeight: 33}}/>
            </Form.Item>
            <Form.Item label="辩证" name="illnessName" rules={[{required: true, message: "请输入辩证信息!"}]}>
              <Input.TextArea autoSize style={{minHeight: 33}}/>
            </Form.Item>

            <Form.Item label="分析" name="diagnoseResult">
              <Input.TextArea autoSize style={{minHeight: 33}}/>
            </Form.Item>
            <Form.Item label="其他问题" name="otherQuestion">
              <Input.TextArea autoSize style={{minHeight: 88}}/>
            </Form.Item>


          </div>
          <div style={{flex: 1}}>

            <Form.Item label="辨病" name="resolutionDisease">
              <Input.TextArea autoSize style={{minHeight: 33}}/>
            </Form.Item>
            <Form.Item label="处方" name="prescriptionPictures">
              <AliyunOSSUpload/>
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
}
